<template>
    <div class="w-full text-center">
        You have no favorite coins
    </div>
</template>

<script>
export default {
  name: "Warning",

};
</script>

<style>

</style>
